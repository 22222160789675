import { openBlock, createElementBlock, normalizeClass, normalizeStyle, renderSlot, createElementVNode, Fragment, renderList, createCommentVNode } from 'vue';

var script = {
    name: 'VirtualScroller',
    emits: ['update:numToleratedItems', 'scroll', 'scroll-index-change', 'lazy-load'],
    props: {
        id: {
            type: String,
            default: null
        },
        style: null,
        class: null,
        items: {
            type: Array,
            default: null
        },
        itemSize: {
            type: [Number, Array],
            default: 0
        },
        scrollHeight: null,
        scrollWidth: null,
        orientation: {
            type: String,
            default: 'vertical'
        },
        numToleratedItems: {
            type: Number,
            default: null
        },
        delay: {
            type: Number,
            default: 0
        },
        lazy: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loaderDisabled: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        showSpacer: {
            type: Boolean,
            default: true
        },
        showLoader: {
            type: Boolean,
            default: false
        },
        tabindex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            first: this.isBoth() ? { rows: 0, cols: 0 } : 0,
            last: this.isBoth() ? { rows: 0, cols: 0 } : 0,
            numItemsInViewport: this.isBoth() ? { rows: 0, cols: 0 } : 0,
            lastScrollPos: this.isBoth() ? { top: 0, left: 0 } : 0,
            d_numToleratedItems: this.numToleratedItems,
            d_loading: this.loading,
            loaderArr: [],
            spacerStyle: {},
            contentStyle: {}
        };
    },
    element: null,
    content: null,
    lastScrollPos: null,
    scrollTimeout: null,
    watch: {
        numToleratedItems(newValue) {
            this.d_numToleratedItems = newValue;
        },
        loading(newValue) {
            this.d_loading = newValue;
        },
        items(newValue, oldValue) {
            if (!oldValue || oldValue.length !== (newValue || []).length) {
                this.init();
            }
        },
        orientation() {
            this.lastScrollPos = this.isBoth() ? { top: 0, left: 0 } : 0;
        }
    },
    mounted() {
        this.init();

        this.lastScrollPos = this.isBoth() ? { top: 0, left: 0 } : 0;
    },
    methods: {
        init() {
            this.setSize();
            this.calculateOptions();
            this.setSpacerSize();
        },
        isVertical() {
            return this.orientation === 'vertical';
        },
        isHorizontal() {
            return this.orientation === 'horizontal';
        },
        isBoth() {
            return this.orientation === 'both';
        },
        scrollTo(options) {
            this.element && this.element.scrollTo(options);
        },
        scrollToIndex(index, behavior = 'auto') {
            const both = this.isBoth();
            const horizontal = this.isHorizontal();
            const first = this.first;
            const { numToleratedItems } = this.calculateNumItems();
            const itemSize = this.itemSize;
            const calculateFirst = (_index = 0, _numT) => (_index <= _numT ? 0 : _index);
            const calculateCoord = (_first, _size) => _first * _size;
            const scrollTo = (left = 0, top = 0) => this.scrollTo({ left, top, behavior });

            if (both) {
                const newFirst = { rows: calculateFirst(index[0], numToleratedItems[0]), cols: calculateFirst(index[1], numToleratedItems[1]) };

                if (newFirst.rows !== first.rows || newFirst.cols !== first.cols) {
                    scrollTo(calculateCoord(newFirst.cols, itemSize[1]), calculateCoord(newFirst.rows, itemSize[0]));
                }
            } else {
                const newFirst = calculateFirst(index, numToleratedItems);

                if (newFirst !== first) {
                    horizontal ? scrollTo(calculateCoord(newFirst, itemSize), 0) : scrollTo(0, calculateCoord(newFirst, itemSize));
                }
            }
        },
        scrollInView(index, to, behavior = 'auto') {
            if (to) {
                const both = this.isBoth();
                const horizontal = this.isHorizontal();
                const { first, viewport } = this.getRenderedRange();
                const scrollTo = (left = 0, top = 0) => this.scrollTo({ left, top, behavior });
                const isToStart = to === 'to-start';
                const isToEnd = to === 'to-end';

                if (isToStart) {
                    if (both) {
                        if (viewport.first.rows - first.rows > index[0]) {
                            scrollTo(viewport.first.cols * this.itemSize[1], (viewport.first.rows - 1) * this.itemSize[0]);
                        } else if (viewport.first.cols - first.cols > index[1]) {
                            scrollTo((viewport.first.cols - 1) * this.itemSize[1], viewport.first.rows * this.itemSize[0]);
                        }
                    } else {
                        if (viewport.first - first > index) {
                            const pos = (viewport.first - 1) * this.itemSize;

                            horizontal ? scrollTo(pos, 0) : scrollTo(0, pos);
                        }
                    }
                } else if (isToEnd) {
                    if (both) {
                        if (viewport.last.rows - first.rows <= index[0] + 1) {
                            scrollTo(viewport.first.cols * this.itemSize[1], (viewport.first.rows + 1) * this.itemSize[0]);
                        } else if (viewport.last.cols - first.cols <= index[1] + 1) {
                            scrollTo((viewport.first.cols + 1) * this.itemSize[1], viewport.first.rows * this.itemSize[0]);
                        }
                    } else {
                        if (viewport.last - first <= index + 1) {
                            const pos = (viewport.first + 1) * this.itemSize;

                            horizontal ? scrollTo(pos, 0) : scrollTo(0, pos);
                        }
                    }
                }
            } else {
                this.scrollToIndex(index, behavior);
            }
        },
        getRenderedRange() {
            const calculateFirstInViewport = (_pos, _size) => Math.floor(_pos / (_size || _pos));

            let firstInViewport = this.first;
            let lastInViewport = 0;

            if (this.element) {
                const both = this.isBoth();
                const horizontal = this.isHorizontal();
                const scrollTop = this.element.scrollTop;
                const scrollLeft = this.element.scrollLeft;

                if (both) {
                    firstInViewport = { rows: calculateFirstInViewport(scrollTop, this.itemSize[0]), cols: calculateFirstInViewport(scrollLeft, this.itemSize[1]) };
                    lastInViewport = { rows: firstInViewport.rows + this.numItemsInViewport.rows, cols: firstInViewport.cols + this.numItemsInViewport.cols };
                } else {
                    const scrollPos = horizontal ? scrollLeft : scrollTop;

                    firstInViewport = calculateFirstInViewport(scrollPos, this.itemSize);
                    lastInViewport = firstInViewport + this.numItemsInViewport;
                }
            }

            return {
                first: this.first,
                last: this.last,
                viewport: {
                    first: firstInViewport,
                    last: lastInViewport
                }
            };
        },
        calculateNumItems() {
            const both = this.isBoth();
            const horizontal = this.isHorizontal();
            const itemSize = this.itemSize;
            const contentPos = this.getContentPosition();
            const contentWidth = this.element ? this.element.offsetWidth - contentPos.left : 0;
            const contentHeight = this.element ? this.element.offsetHeight - contentPos.top : 0;
            const calculateNumItemsInViewport = (_contentSize, _itemSize) => Math.ceil(_contentSize / (_itemSize || _contentSize));
            const calculateNumToleratedItems = (_numItems) => Math.ceil(_numItems / 2);
            const numItemsInViewport = both
                ? { rows: calculateNumItemsInViewport(contentHeight, itemSize[0]), cols: calculateNumItemsInViewport(contentWidth, itemSize[1]) }
                : calculateNumItemsInViewport(horizontal ? contentWidth : contentHeight, itemSize);

            const numToleratedItems = this.d_numToleratedItems || (both ? [calculateNumToleratedItems(numItemsInViewport.rows), calculateNumToleratedItems(numItemsInViewport.cols)] : calculateNumToleratedItems(numItemsInViewport));

            return { numItemsInViewport, numToleratedItems };
        },
        calculateOptions() {
            const both = this.isBoth();
            const first = this.first;
            const { numItemsInViewport, numToleratedItems } = this.calculateNumItems();
            const calculateLast = (_first, _num, _numT, _isCols) => this.getLast(_first + _num + (_first < _numT ? 2 : 3) * _numT, _isCols);
            const last = both
                ? { rows: calculateLast(first.rows, numItemsInViewport.rows, numToleratedItems[0]), cols: calculateLast(first.cols, numItemsInViewport.cols, numToleratedItems[1], true) }
                : calculateLast(first, numItemsInViewport, numToleratedItems);

            this.last = last;
            this.numItemsInViewport = numItemsInViewport;
            this.d_numToleratedItems = numToleratedItems;
            this.$emit('update:numToleratedItems', this.d_numToleratedItems);

            if (this.showLoader) {
                this.loaderArr = both ? Array.from({ length: numItemsInViewport.rows }).map(() => Array.from({ length: numItemsInViewport.cols })) : Array.from({ length: numItemsInViewport });
            }

            if (this.lazy) {
                this.$emit('lazy-load', { first, last });
            }
        },
        getLast(last = 0, isCols) {
            if (this.items) {
                return Math.min(isCols ? (this.columns || this.items[0]).length : this.items.length, last);
            }

            return 0;
        },
        getContentPosition() {
            if (this.content) {
                const style = getComputedStyle(this.content);
                const left = parseInt(style.paddingLeft, 10) + Math.max(parseInt(style.left, 10), 0);
                const right = parseInt(style.paddingRight, 10) + Math.max(parseInt(style.right, 10), 0);
                const top = parseInt(style.paddingTop, 10) + Math.max(parseInt(style.top, 10), 0);
                const bottom = parseInt(style.paddingBottom, 10) + Math.max(parseInt(style.bottom, 10), 0);

                return { left, right, top, bottom, x: left + right, y: top + bottom };
            }

            return { left: 0, right: 0, top: 0, bottom: 0, x: 0, y: 0 };
        },
        setSize() {
            if (this.element) {
                const both = this.isBoth();
                const horizontal = this.isHorizontal();
                const parentElement = this.element.parentElement;
                const width = this.scrollWidth || `${this.element.offsetWidth || parentElement.offsetWidth}px`;
                const height = this.scrollHeight || `${this.element.offsetHeight || parentElement.offsetHeight}px`;
                const setProp = (_name, _value) => (this.element.style[_name] = _value);

                if (both || horizontal) {
                    setProp('height', height);
                    setProp('width', width);
                } else {
                    setProp('height', height);
                }
            }
        },
        setSpacerSize() {
            const items = this.items;

            if (items) {
                const both = this.isBoth();
                const horizontal = this.isHorizontal();
                const contentPos = this.getContentPosition();
                const setProp = (_name, _value, _size, _cpos = 0) => (this.spacerStyle = { ...this.spacerStyle, ...{ [`${_name}`]: (_value || []).length * _size + _cpos + 'px' } });

                if (both) {
                    setProp('height', items, this.itemSize[0], contentPos.y);
                    setProp('width', this.columns || items[1], this.itemSize[1], contentPos.x);
                } else {
                    horizontal ? setProp('width', this.columns || items, this.itemSize, contentPos.x) : setProp('height', items, this.itemSize, contentPos.y);
                }
            }
        },
        setContentPosition(pos) {
            if (this.content) {
                const both = this.isBoth();
                const horizontal = this.isHorizontal();
                const first = pos ? pos.first : this.first;
                const calculateTranslateVal = (_first, _size) => _first * _size;

                const setTransform = (_x = 0, _y = 0) => {
                    this.contentStyle = { ...this.contentStyle, ...{ transform: `translate3d(${_x}px, ${_y}px, 0)` } };
                };

                if (both) {
                    setTransform(calculateTranslateVal(first.cols, this.itemSize[1]), calculateTranslateVal(first.rows, this.itemSize[0]));
                } else {
                    const translateVal = calculateTranslateVal(first, this.itemSize);

                    horizontal ? setTransform(translateVal, 0) : setTransform(0, translateVal);
                }
            }
        },
        onScrollPositionChange(event) {
            const target = event.target;
            const both = this.isBoth();
            const horizontal = this.isHorizontal();
            const contentPos = this.getContentPosition();
            const calculateScrollPos = (_pos, _cpos) => (_pos ? (_pos > _cpos ? _pos - _cpos : _pos) : 0);
            const calculateCurrentIndex = (_pos, _size) => Math.floor(_pos / (_size || _pos));

            const calculateTriggerIndex = (_currentIndex, _first, _last, _num, _numT, _isScrollDownOrRight) => {
                return _currentIndex <= _numT ? _numT : _isScrollDownOrRight ? _last - _num - _numT : _first + _numT - 1;
            };

            const calculateFirst = (_currentIndex, _triggerIndex, _first, _last, _num, _numT, _isScrollDownOrRight) => {
                if (_currentIndex <= _numT) return 0;
                else return Math.max(0, _isScrollDownOrRight ? (_currentIndex < _triggerIndex ? _first : _currentIndex - _numT) : _currentIndex > _triggerIndex ? _first : _currentIndex - 2 * _numT);
            };

            const calculateLast = (_currentIndex, _first, _last, _num, _numT, _isCols) => {
                let lastValue = _first + _num + 2 * _numT;

                if (_currentIndex >= _numT) {
                    lastValue += _numT + 1;
                }

                return this.getLast(lastValue, _isCols);
            };

            const scrollTop = calculateScrollPos(target.scrollTop, contentPos.top);
            const scrollLeft = calculateScrollPos(target.scrollLeft, contentPos.left);

            let newFirst = both ? { rows: 0, cols: 0 } : 0;
            let newLast = this.last;
            let isRangeChanged = false;
            let newScrollPos = this.lastScrollPos;

            if (both) {
                const isScrollDown = this.lastScrollPos.top <= scrollTop;
                const isScrollRight = this.lastScrollPos.left <= scrollLeft;
                const currentIndex = { rows: calculateCurrentIndex(scrollTop, this.itemSize[0]), cols: calculateCurrentIndex(scrollLeft, this.itemSize[1]) };
                const triggerIndex = {
                    rows: calculateTriggerIndex(currentIndex.rows, this.first.rows, this.last.rows, this.numItemsInViewport.rows, this.d_numToleratedItems[0], isScrollDown),
                    cols: calculateTriggerIndex(currentIndex.cols, this.first.cols, this.last.cols, this.numItemsInViewport.cols, this.d_numToleratedItems[1], isScrollRight)
                };

                newFirst = {
                    rows: calculateFirst(currentIndex.rows, triggerIndex.rows, this.first.rows, this.last.rows, this.numItemsInViewport.rows, this.d_numToleratedItems[0], isScrollDown),
                    cols: calculateFirst(currentIndex.cols, triggerIndex.cols, this.first.cols, this.last.cols, this.numItemsInViewport.cols, this.d_numToleratedItems[1], isScrollRight)
                };
                newLast = {
                    rows: calculateLast(currentIndex.rows, newFirst.rows, this.last.rows, this.numItemsInViewport.rows, this.d_numToleratedItems[0]),
                    cols: calculateLast(currentIndex.cols, newFirst.cols, this.last.cols, this.numItemsInViewport.cols, this.d_numToleratedItems[1], true)
                };

                isRangeChanged = newFirst.rows !== this.first.rows || newLast.rows !== this.last.rows || newFirst.cols !== this.first.cols || newLast.cols !== this.last.cols;
                newScrollPos = { top: scrollTop, left: scrollLeft };
            } else {
                const scrollPos = horizontal ? scrollLeft : scrollTop;
                const isScrollDownOrRight = this.lastScrollPos <= scrollPos;
                const currentIndex = calculateCurrentIndex(scrollPos, this.itemSize);
                const triggerIndex = calculateTriggerIndex(currentIndex, this.first, this.last, this.numItemsInViewport, this.d_numToleratedItems, isScrollDownOrRight);

                newFirst = calculateFirst(currentIndex, triggerIndex, this.first, this.last, this.numItemsInViewport, this.d_numToleratedItems, isScrollDownOrRight);
                newLast = calculateLast(currentIndex, newFirst, this.last, this.numItemsInViewport, this.d_numToleratedItems);
                isRangeChanged = newFirst !== this.first || newLast !== this.last;
                newScrollPos = scrollPos;
            }

            return {
                first: newFirst,
                last: newLast,
                isRangeChanged,
                scrollPos: newScrollPos
            };
        },
        onScrollChange(event) {
            const { first, last, isRangeChanged, scrollPos } = this.onScrollPositionChange(event);

            if (isRangeChanged) {
                const newState = { first, last };

                this.setContentPosition(newState);

                this.first = first;
                this.last = last;
                this.lastScrollPos = scrollPos;

                this.$emit('scroll-index-change', newState);

                if (this.lazy) {
                    this.$emit('lazy-load', newState);
                }
            }
        },
        onScroll(event) {
            this.$emit('scroll', event);

            if (this.delay) {
                if (this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }

                if (!this.d_loading && this.showLoader) {
                    const { isRangeChanged: changed } = this.onScrollPositionChange(event);

                    changed && (this.d_loading = true);
                }

                this.scrollTimeout = setTimeout(() => {
                    this.onScrollChange(event);

                    if (this.d_loading && this.showLoader && !this.lazy) {
                        this.d_loading = false;
                    }
                }, this.delay);
            } else {
                this.onScrollChange(event);
            }
        },
        getOptions(renderedIndex) {
            const count = (this.items || []).length;
            const index = this.isBoth() ? this.first.rows + renderedIndex : this.first + renderedIndex;

            return {
                index,
                count,
                first: index === 0,
                last: index === count - 1,
                even: index % 2 === 0,
                odd: index % 2 !== 0
            };
        },
        getLoaderOptions(index, extOptions) {
            let count = this.loaderArr.length;

            return {
                index,
                count,
                first: index === 0,
                last: index === count - 1,
                even: index % 2 === 0,
                odd: index % 2 !== 0,
                ...extOptions
            };
        },
        elementRef(el) {
            this.element = el;
        },
        contentRef(el) {
            this.content = el;
        }
    },
    computed: {
        containerClass() {
            return [
                'p-virtualscroller',
                {
                    'p-both-scroll': this.isBoth(),
                    'p-horizontal-scroll': this.isHorizontal()
                },
                this.class
            ];
        },
        contentClass() {
            return [
                'p-virtualscroller-content',
                {
                    'p-virtualscroller-loading': this.d_loading
                }
            ];
        },
        loaderClass() {
            return [
                'p-virtualscroller-loader',
                {
                    'p-component-overlay': !this.$slots.loader
                }
            ];
        },
        loadedItems() {
            const items = this.items;

            if (items && !this.d_loading) {
                if (this.isBoth()) {
                    return items.slice(this.first.rows, this.last.rows).map((item) => (this.columns ? item : item.slice(this.first.cols, this.last.cols)));
                } else if (this.isHorizontal() && this.columns) return items;
                else return items.slice(this.first, this.last);
            }

            return [];
        },
        loadedRows() {
            return this.d_loading ? (this.loaderDisabled ? this.loaderArr : []) : this.loadedItems;
        },
        loadedColumns() {
            if (this.columns) {
                const both = this.isBoth();
                const horizontal = this.isHorizontal();

                if (both || horizontal) {
                    return this.d_loading && this.loaderDisabled ? (both ? this.loaderArr[0] : this.loaderArr) : this.columns.slice(both ? this.first.cols : this.first, both ? this.last.cols : this.last);
                }
            }

            return this.columns;
        }
    }
};

const _hoisted_1 = ["tabindex"];
const _hoisted_2 = {
  key: 1,
  class: "p-virtualscroller-loading-icon pi pi-spinner pi-spin"
};

function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$props.disabled)
    ? (openBlock(), createElementBlock("div", {
        key: 0,
        ref: $options.elementRef,
        class: normalizeClass($options.containerClass),
        tabindex: $props.tabindex,
        style: normalizeStyle($props.style),
        onScroll: _cache[0] || (_cache[0] = (...args) => ($options.onScroll && $options.onScroll(...args)))
      }, [
        renderSlot(_ctx.$slots, "content", {
          styleClass: $options.contentClass,
          items: $options.loadedItems,
          getItemOptions: $options.getOptions,
          loading: $data.d_loading,
          getLoaderOptions: $options.getLoaderOptions,
          itemSize: $props.itemSize,
          rows: $options.loadedRows,
          columns: $options.loadedColumns,
          contentRef: $options.contentRef,
          spacerStyle: $data.spacerStyle,
          contentStyle: $data.contentStyle,
          vertical: $options.isVertical(),
          horizontal: $options.isHorizontal(),
          both: $options.isBoth()
        }, () => [
          createElementVNode("div", {
            ref: $options.contentRef,
            class: normalizeClass($options.contentClass),
            style: normalizeStyle($data.contentStyle)
          }, [
            (openBlock(true), createElementBlock(Fragment, null, renderList($options.loadedItems, (item, index) => {
              return renderSlot(_ctx.$slots, "item", {
                key: index,
                item: item,
                options: $options.getOptions(index)
              })
            }), 128))
          ], 6)
        ]),
        ($props.showSpacer)
          ? (openBlock(), createElementBlock("div", {
              key: 0,
              class: "p-virtualscroller-spacer",
              style: normalizeStyle($data.spacerStyle)
            }, null, 4))
          : createCommentVNode("", true),
        (!$props.loaderDisabled && $props.showLoader && $data.d_loading)
          ? (openBlock(), createElementBlock("div", {
              key: 1,
              class: normalizeClass($options.loaderClass)
            }, [
              (_ctx.$slots && _ctx.$slots.loader)
                ? (openBlock(true), createElementBlock(Fragment, { key: 0 }, renderList($data.loaderArr, (_, index) => {
                    return renderSlot(_ctx.$slots, "loader", {
                      key: index,
                      options: $options.getLoaderOptions(index, $options.isBoth() && { numCols: _ctx.d_numItemsInViewport.cols })
                    })
                  }), 128))
                : (openBlock(), createElementBlock("i", _hoisted_2))
            ], 2))
          : createCommentVNode("", true)
      ], 46, _hoisted_1))
    : (openBlock(), createElementBlock(Fragment, { key: 1 }, [
        renderSlot(_ctx.$slots, "default"),
        renderSlot(_ctx.$slots, "content", {
          items: $props.items,
          rows: $props.items,
          columns: $options.loadedColumns
        })
      ], 64))
}

function styleInject(css, ref) {
  if ( ref === void 0 ) ref = {};
  var insertAt = ref.insertAt;

  if (!css || typeof document === 'undefined') { return; }

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css_248z = "\n.p-virtualscroller {\n    position: relative;\n    overflow: auto;\n    contain: strict;\n    transform: translateZ(0);\n    will-change: scroll-position;\n    outline: 0 none;\n}\n.p-virtualscroller-content {\n    position: absolute;\n    top: 0;\n    left: 0;\n    contain: content;\n    min-height: 100%;\n    min-width: 100%;\n    will-change: transform;\n}\n.p-virtualscroller-spacer {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 1px;\n    width: 1px;\n    transform-origin: 0 0;\n    pointer-events: none;\n}\n.p-virtualscroller .p-virtualscroller-loader {\n    position: sticky;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n.p-virtualscroller-loader.p-component-overlay {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n";
styleInject(css_248z);

script.render = render;

export { script as default };
